<template>
  <div class="edit-route">
    <el-form
      :model="form"
      ref="form"
      :inline="true"
      size="normal"
      v-loading="loading"
    >
      <el-form-item
        id="group_name"
        label-width="300px"
        label="组别名称"
        prop="group_name"
        style="margin-bottom: 35px"
      >
        <el-input
          ref="group_name"
          :disabled="true"
          v-model="form.group_name"
          placeholder="请输入组别"
        ></el-input>
      </el-form-item>

      <el-form-item
        id="selectedWeek"
        label="门派"
        prop="selectedWeek"
        style="margin-left: 25rem"
      >
        <el-select
          v-model="form.selectedWeek"
          style="width: 200px"
          placeholder="请选择"
          @change="addDataRight"
        >
          <el-option
            v-for="item in weeks"
            :key="item.id"
            :label="item.group_name"
            :value="item.group"
          >
          </el-option>
          <el-option v-if="type_id != 'final'" label="替补组" value="替补组">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="type_id != 'final'"
        label="搜索门派(全局)"
        size="normal"
      >
        <el-input
          style="width: 200px"
          v-model="form.searchQuery"
          placeholder="请输入搜索内容"
          clearable
        >
          <template slot="append">
            <el-button
              icon="el-icon-search"
              @click="handleSearchClick"
            ></el-button>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: center" class="content">
      <div style="margin-right: 10px">{{ form.group_name }}组门派</div>
      <el-transfer
        style="text-align: left; display: inline-block"
        v-model="value4"
        filterable
        :left-default-checked="leftChecked"
        :right-default-checked="rightChecked"
        :titles="['门派名称-ID', '门派名称-ID']"
        :button-texts="['到左边', '到右边']"
        :format="{
          noChecked: '${total}',
          hasChecked: '${checked}/${total}',
        }"
        :props="{
          key: 'sect_id',
          label: 'sect_name',
          value: 'group',
        }"
        @change="handleChange"
        :data="allData"
      >
        <span slot="left-footer"> 门派名称</span>
        <span slot="left-footer"> 门派ID </span>
        <span slot="left-footer"> 组 </span>
        <span slot="right-footer"> 门派名称 </span>
        <span slot="right-footer"> 门派ID </span>
        <span slot="right-footer"> 组 </span>
        <span slot-scope="{ option }">
          <span style="float: left">{{ option.sect_name }} </span>
          <span style="position: absolute; left: 200px">{{
            option.sect_id
          }}</span>
          <span style="float: right; margin-right: 10px"
            >{{ option.group }}
          </span>
        </span>
      </el-transfer>
    </div>
    <!-- 提交，取消 -->
    <div class="bottom">
      <el-button type="primary" size="large" @click="submitData"
        >提交</el-button
      >
      <el-button type="primary" @click="goBack()" size="large">取消</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'gruopManage_all',
  props: {
    group_nameS4: {
      type: String,
    },
    match_id: {
      type: String,
    },
    season_id: {
      type: String,
    },
    sectIdAndName: {
      type: String,
    },
    _id: {
      type: String,
    },
    stage: {
      type: String,
    },
    type_id: {
      type: String,
    },
    match_type: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        group_name: '',
        selectedWeek: '',
        searchQuery: '',
      },
      group_id: '',
      loading: false,
      to_group_id: '',
      originalData: [], //左侧数据
      addedData: [], //右侧数据
      value4: [],
      allData: [], //全部数据
      leftChecked: [], // 左侧选中的项
      rightChecked: [], // 右侧选中的项
      weeks: [], //下拉框
    }
  },
  watch: {
    //传来值时
    type_id() {
      if (this.type_id === 'final') {
        this.queryteam_final()
      } else {
        this.queryteam()
      }
      this.queryteamSelect()
    },
  },
  methods: {
    ...mapActions('tabs', ['removeTab', 'removeTabByPath']),
    //左侧分组总决赛
    async queryteam_final() {
      this.loading = true
      //请求接口
      const { data } = await this.$http.match_subject.season_group_list_new({
        pageNum: 1,
        season_id: this.season_id,
      })
      this.loading = false
      console.log(
        '🚀 ~ file: gruopManage_all.vue:180 ~ queryteam_final ~ data:',
        data
      )
      this.form.group_name = this.group_nameS4
      this.originalData = data.data[0].sectInfo
      this.allData = [...this.originalData]
    },
    // 左侧分组常规赛、预选赛
    async queryteam() {
      let stage = ''
      if (this.type_id != 'final') {
        stage = this.stage
      }
      const { data } = await this.$http.match.s4_match_team_all({
        season_id: this.season_id,
        match_id: this.match_id,
        group_id: this._id,
        to_group_id: this._id,
        stage: stage,
      })
      this.form.group_name = this.group_nameS4
      this.originalData = data
      this.allData = [...this.originalData]
    },
    //下拉框内容
    async queryteamSelect() {
      let stage = ''
      if (this.type_id != 'final') {
        stage = this.stage
      }
      const { data } = await this.$http.match.s4_match_season_all({
        season_id: this.season_id,
        match_id: this.match_id,
        stage: stage,
      })
      this.weeks = data.group.filter(
        (week) => week.group_name !== this.group_nameS4
      )
    },
    // 下拉框点击事件
    async addDataRight() {
      if (this.form.selectedWeek === '替补组') {
        const { data } = await this.$http.match.s4_match_team_all({
          season_id: this.season_id,
          match_id: this.match_id,
          group_id: 1,
          to_group_id: 1,
          stage: this.stage,
        })
        this.addedData = data
        this.allData = [...this.originalData, ...this.addedData]
        this.value4 = this.addedData.map((item) => item.sect_id)
      } else if (this.type_id != 'final') {
        const index = this.weeks.findIndex(
          (item) => item.group === this.form.selectedWeek
        )
        const foundGroup = this.weeks[index]
        // console.log("找到的group项：", foundGroup);
        const { data } = await this.$http.match.s4_match_team_all({
          season_id: this.season_id,
          match_id: this.match_id,
          group_id: foundGroup._id,
          to_group_id: foundGroup._id,
          stage: this.stage,
        })
        this.addedData = data
        this.allData = [...this.originalData, ...this.addedData]
        // 更新右侧选中的数据的 key 值
        this.value4 = this.addedData.map((item) => item.sect_id)
      } else {
        const { data } = await this.$http.match_subject.season_group_list_new({
          pageNum: 1,
          season_id: this.season_id,
        })
        this.addedData = data.data[0].sectInfo
        this.value4 = this.addedData.map((item) => item.sect_id)
      }
    },
    //搜索
    async handleSearchClick() {
      this.addedData = []
      let stage = ''
      if (this.type_id != 'final') {
        stage = this.stage
      }
      const res = await this.$http.match.s4_match_team_all({
        season_id: this.season_id,
        match_id: this.match_id,
        sectIdAndName: this.form.searchQuery,
        stage: stage,
      })

      if (res.data.length <= 0) {
        this.$message.error('请输入正确的门派ID或门派名称')
        return
      }
      let groupString = '第' + res.data[0].group + '组'
      if (groupString === this.group_nameS4) {
        this.$message.error('搜索门派已在左侧')
        return
      }
      if (res.data[0].group != this.form.group_name) {
        this.addedData = res.data
        this.allData = [...this.originalData, ...this.addedData]
        // 更新右侧选中的数据的 key 值
        this.value4 = this.addedData.map((item) => item.sect_id)
      } else {
        this.addedData = res.data
        this.value4 = this.addedData.map((item) => item.sect_id)
      }
    },
    //左右变化
    handleChange(value, direction, movedKeys) {
      if (direction === 'right') {
        console.log(
          '🚀 ~ file: gruopManage_all.vue:291 ~ handleChange ~ value:',
          value
        )
      }
    },
    //提交
    async submitData() {
      const leftData = this.allData.filter(
        (item) => !this.value4.includes(item.sect_id)
      )
      const rightData = this.allData.filter((item) =>
        this.value4.includes(item.sect_id)
      )
      console.log('左侧数组:', leftData, '右侧数组:', rightData)
      // 处理左侧数据
      const weeksWithoutGroup = leftData.map(({ sect_id, sect_name }) => ({
        sect_id,
        sect_name,
      }))
      let toid = ''
      this.weeks.forEach((week, index) => {
        if (week.group === this.form.selectedWeek) {
          toid = week._id
        }
      })
      this.loading = true
      let stage = ''
      if (this.type_id != 'final') {
        stage = this.stage
      }
      let {
        data,
        errorCode,
      } = await this.$http.match_subject.save_season_group({
        _id: this._id,
        season_id: this.season_id,
        match_id: this.match_id,
        group_name: this.form.group_name,
        to_group_id: toid,
        team_info: weeksWithoutGroup,
        stage: stage,
      })
      this.loading = false
      if (errorCode != '0000') {
        return
      }
      this.$message({
        type: 'success',
        message: '保存成功',
      })
      this.goBack()
    },
    // 取消
    goBack() {
      this.removeTabByPath(`/match/subject/gruopLeague`)
      this.$router.push({
        path: `/match/subject/gruopLeague`,
        query: {
          match_id: this.match_id,
          season_id: this.season_id,
          type_id: this.type_id,
          match_type: this.match_type,
        },
      })
    },
  },
}
</script>

<style scoped>
.transfer-footer {
  margin-left: 20px;
  padding: 6px 5px;
}
.one {
  width: 200px !important;
  margin: 0 0 50px 800px;
}
.edit-route >>> .el-transfer-panel__filter {
  display: none;
}
.edit-route >>> .el-transfer-panel {
  width: 450px;
}
.edit-route >>> .el-transfer-panel__body {
  height: 410px;
}
.edit-route >>> .el-checkbox-group {
  height: 410px;
  margin-top: 40px !important;
}
.edit-route >>> .el-transfer-panel__item {
  position: relative;
  margin: 0 50px 0 20px !important;
}

.edit-route >>> .el-transfer-panel__footer {
  width: 450px;
  display: flex;
  justify-content: space-between;
  padding-left: 50px !important;
  align-items: center;
  border: 0;
  margin-top: -60px;
}
.bottom {
  display: flex;
  justify-content: center;
}
.content {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
</style>
